import { Grid } from '@mui/material'
import { CKForm, CKFormBody } from 'components/Form/CKForm'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKInput from 'components/MaterialKit/MKInput'
import { FC } from 'react'
import Organisation from 'models/Organisation'

interface Props {
    organisation: Organisation
    errors: Record<string, string>
}

export const GeneralForm: FC<Props> = ({ organisation, errors }) => {
    if (!organisation) return null
    return (
        <CKForm>
            <CKFormBody>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MKInput
                            label="Naam"
                            title="Naam"
                            error={errors['name'] ? true : false}
                            defaultValue={organisation.$name}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                organisation.$name = e.target.value
                            }}
                            required
                            fullWidth
                        />
                        <CKFormFeedback>{errors['name']}</CKFormFeedback>
                    </Grid>
                    <Grid item xs={12}>
                        <MKInput
                            type="standard"
                            multiline
                            defaultValue={organisation.$description}
                            placeholder="Geef een beschrijving van de organisatie zodat mensen je weten wat je doet..."
                            title="Beschrijving"
                            label="Beschrijving"
                            onChange={(e) => {
                                organisation.$description = e.target.value
                            }}
                            InputLabelProps={{ shrink: true }}
                            rows={8}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CKFormBody>
        </CKForm>
    )
} 