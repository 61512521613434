// import './style.scss';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import DeleteIcon from '@mui/icons-material/Delete'
import EmailIcon from '@mui/icons-material/Email'
import LockIcon from '@mui/icons-material/Lock'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import NotificationsIcon from '@mui/icons-material/Notifications'
import bgImage2 from 'assets/images/bgImage2.jpg'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBadge, CKPageBody, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import DeleteAccount from './DeleteAccount'
import EmailChange from './EmailChange'
import EmailNotificationSettings from './EmailNotificationSettings'
import LocationSettings from './LocationSettings'
import MobileNotificationSettings from './MobileNotificationSettings'
import PasswordChange from './PasswordChange'

interface Props {}

const PageUserSettings: FC<Props> = () => {
    const settingsMenu = {
        Meldingen: {
            notifications: {
                title: 'Pushmeldingen',
                icon: <NotificationsIcon />,
                onlyMobile: true,
                component: <MobileNotificationSettings />,
            },
            emails: {
                title: 'E-mails',
                icon: <EmailIcon />,
                component: <EmailNotificationSettings />,
            },
            location: {
                title: 'Locatie',
                icon: (
                    <FontAwesomeIcon
                        fontSize="0.75rem"
                        style={{ width: '0.75rem' }}
                        icon={faLocationDot}
                        className="me-1"
                    />
                ),
                component: <LocationSettings />,
            },
        },
        Account: {
            email: {
                title: 'E-mailadres',
                icon: <AlternateEmailIcon />,
                component: <EmailChange />,
            },
            password: {
                title: 'Wachtwoord',
                icon: <LockIcon />,
                component: <PasswordChange />,
            },
            'delete-account': {
                title: 'Verwijderen',
                icon: <DeleteIcon />,
                component: <DeleteAccount />,
            },
        },
    }

    return (
        <div className="ck-settings-page">
            <CKHero className="ck-chat-hero" bgImage={bgImage2} size="xs" />
            <CKPageContent className="ck-settings-page-content">
                <div className="ck-settings-container">
                    <div className="ck-settings-hero mb-4">
                        <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                            <ManageAccountsIcon fontSize="large" style={{ color: 'white' }} />
                        </CKPageBadge>
                        <CKPageTitle>Instellingen</CKPageTitle>
                    </div>
                    <CKPageBody>
                        <CKSidebarTabs sections={settingsMenu} />
                    </CKPageBody>
                </div>
            </CKPageContent>
        </div>
    )
}
export default PageUserSettings
