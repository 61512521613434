import bgImage from 'assets/images/bar_sidebar.jpg'
import { CKLoading } from 'components/Generic/CKLoading'
import { CKHero } from 'components/UI/CKHero'
import { EventIcon } from 'components/UI/CKIcons'
import { CKPageBadge, CKPageContent } from 'components/UI/CKPageContent'
import Event from 'models/Event'
import { FC } from 'react'

export interface IEventPageContainer {
    event: Event
    children: any
    loading: boolean
    adminPanel?: boolean
}

export const EventPageContainer: FC<IEventPageContainer> = ({
    event,
    loading,
    children,
    adminPanel,
}) => {
    return (
        <>
            <CKHero
                size={adminPanel ? 'xxs' : 'md'}
                bgImage={event && event.$banner ? event.$bannerUrl : bgImage}
            />
            <CKPageContent>
                {loading ? (
                    <CKLoading />
                ) : (
                    <>
                        {event?.$id && event?.$logoUrl ? (
                            <CKPageBadge src={event.$logoUrl}></CKPageBadge>
                        ) : (
                            <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                                <EventIcon fontSize="large" style={{ color: 'white' }} />
                            </CKPageBadge>
                        )}
                        {children}
                    </>
                )}
            </CKPageContent>
        </>
    )
}
